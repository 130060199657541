import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Chart } from './Chart';
import { FullInfoContainer, PowerMetricsColor } from '../../../components/globalStyles';
import '../getPower/GetPower.css';

const CHART_MAX_KW = 4;

export default function FullInfo({ station, periodKwh, lastJob, chargeStatus, onlineStatus }) {
  const { t } = useTranslation();

  return (
    <FullInfoContainer id="fullInfoContainer" className="fullInfoCont">
      <Row id="fullInfoChartBox" className="justify-content-center mb-2">
        <Chart
          power={{
            currentKw: !onlineStatus ? 0 : Number(lastJob?.powerWt) / 1000,
            maxKw: station?.maxPowerWt > 0 ? station.maxPowerWt / 1000 : CHART_MAX_KW,
          }}
          voltage={!onlineStatus ? 0 : Number(Math.round(lastJob?.voltage))}
        />
      </Row>
      <Row id="fullInfoKwtMetricsBox" className="justify-content-center">
        <Col id="fullInfoKwtChargedBox" className="text-center mb-2">
          <PowerMetricsColor id="fullInfoKwtChargedTitle" className="mb-0 textTitle voltTitle">
            {t('charging')}
          </PowerMetricsColor>
          <p id="fullInfoKwtChargedInfo" className="textInfo voltTitle text">
            {chargeStatus} {t('wt')}
          </p>
        </Col>
        <Col id="fullInfoKwtPowerBox" xs lg={6} className="text-center">
          <PowerMetricsColor id="fullInfoKwtPowerTitle" className="mb-0 textTitle voltTitle">
            {t('payedForKwh')}
            {/*  todo rename css*/}
          </PowerMetricsColor>
          <p id="fullInfoKwtPowerInfo" className="textInfo voltTitle text">
            {periodKwh} {t('btns.startKwt')}
          </p>
        </Col>
      </Row>
    </FullInfoContainer>
  );
}
