/* eslint-disable */
import { useState } from 'react';
import ModalContacts from '../contactsPage/modal/ModalContacts';
import { useTranslation } from 'react-i18next';
import BtnCommon from '../../components/buttons/BtnCommon';
import './startErrorPage.css';
import { Button } from '../../shared/ui';
import { useEffect } from 'react';

const StartErrorPage = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  //eslint-disable-next-line
  const [pathName, portNumber] = window.location.href.split('=');
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className={'errorStart-container'}>
        {!isOpenModal && (
          <>
            <div className={'errorStart-message'}>{t('errorStart.alertMessage', { portNumber })}</div>
            <Button className={'mainBtnText'} link={`/start?station=${portNumber}`}>
              {t('startNew')}
            </Button>
            <BtnCommon funct={setIsOpenModal} textButton={'callUs'} argForFunct={true} />
          </>
        )}
        {isOpenModal && <ModalContacts modalOpen={isOpenModal} setModalOpen={setIsOpenModal} />}
      </div>
    </>
  );
};

export default StartErrorPage;
