/* eslint-disable */
import { Col, Container, Row } from 'react-bootstrap';
import '../../components/buttons/mainBtn.css';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../errorPage/ErrorPage';
import Spinner from '../../components/loaders/globalSpinner/Spinner';
import { StationCard } from '@widgets/StationCard';
import { api, useGetBloodyLitersSavedQuery } from '../../redux/api';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getGeolocationWithPermission } from '../../hookServices/getGeolocationWithPermission';
import { ENV } from '../../environment';
import './HomePage.css';
import SVGContainer from '../../components/svgComponents/SVGContainer';
import SVGBanner from '../../components/svgComponents/SVGBanner';
import { getCookie } from '../../hookServices/cookiesManager';

import Box from '@mui/material/Box';
import { Button } from '@ui';

export default function HomePage({ fingerPrint }) {
  const { data: bloodyLitters, isLoading: bloodyIsLoading } = useGetBloodyLitersSavedQuery();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [getStationStatusAll] = api.useLazyGetStationStatusAllQuery();
  const [getStationStatusNearest] = api.useLazyGetNearestStationQuery();

  const [isLocation, setIsLocation] = useState(false);
  const [stationsArr, setStationsArr] = useState();

  const [allStationsStatus, setAllStationStatus] = useState(null);
  const [errorAllStationsStatus, setErrorAllStationsStatus] = useState();
  const [setLoadingAllStationsStatus] = useState();

  const [nearestStationsStatus, setNearestStationStatus] = useState(null);
  const [errorNearestStationStatus, setErrorNearestStationStatus] = useState();
  const [loadingNearestStationStatus, setLoadingNearestStationStatus] = useState();

  const [isOpenScanner, setIsOpenScanner] = useState(false);

  const [currentBalance, setCurrentBalance] = useState(null);

  const showScannerButton = ENV === 'stage';

  const COST_PER_KWT = 15;
  const INITIAL_KWT = 44;

  const getAllStations = async () => {
    if (!allStationsStatus) {
      const { data, isSuccess, isLoading, isError, error } = await getStationStatusAll(fingerPrint);
      if (isLoading && !isSuccess && !isError) {
        setLoadingAllStationsStatus(true);
        setLoadingNearestStationStatus(false);
      }
      if (isSuccess && !isError) {
        setAllStationStatus(data);
        setNearestStationStatus(null);
      }
      if (!isSuccess && isError) {
        setErrorAllStationsStatus(error);
        setErrorNearestStationStatus(undefined);
      }
    }
  };
  const getNearestStation = async position => {
    const { data, isSuccess, isLoading, isError, error } = await getStationStatusNearest({
      lat: position.latitude,
      lon: position.longitude,
      top: '',
      distanceM: '',
      fingerPrint,
    });
    if (isLoading && !isSuccess && !isError) {
      setLoadingAllStationsStatus(false);
      setLoadingNearestStationStatus(true);
    }
    if (isSuccess && !isError) {
      setAllStationStatus(null);
      setNearestStationStatus(data);
    }
    if (!isSuccess && isError) {
      setErrorAllStationsStatus(undefined);
      setErrorNearestStationStatus(error);
    }
  };

  function handleGeolocationSuccess(position) {
    getNearestStation(position.coords);
  }

  function handleGeolocationError(error) {
    getAllStations();
  }

  function getGeolocation() {
    const position = getCookie('userLocation');
    if (position) {
      getNearestStation(position);
    } else {
      getGeolocationWithPermission(getAllStations, handleGeolocationError, handleGeolocationSuccess);
    }
  }

  useEffect(() => {
    getGeolocation();
  }, []);

  useEffect(() => {
    if (allStationsStatus && !nearestStationsStatus) {
      setStationsArr(allStationsStatus);
    }
    if (!allStationsStatus && nearestStationsStatus) {
      setStationsArr(nearestStationsStatus);
    }
  }, [allStationsStatus, nearestStationsStatus]);

  if ((errorNearestStationStatus && errorNearestStationStatus !== 400) || errorAllStationsStatus) {
    return <ErrorPage errorHeader={t('errorDevHeader')} errorBody={t('errorDevBody')} />;
  }
  if (!stationsArr) {
    return (
      <div style={{ height: '100lvh', width: '100lvw' }}>
        <Spinner />
      </div>
    );
  }

  return (
    <div id="homePage" style={{ margin: '10lvh 0 5lvh' }}>
      <div
        onClick={() => {
          navigate('/scanner');
        }}
        className={'qr-button'}
        style={{ top: '74lvh' }}
      >
        <SVGContainer>
          <SVGBanner />
        </SVGContainer>
      </div>
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {!bloodyIsLoading && (
            <span
              style={{
                fontSize: '22px',
                letterSpacing: '0.5px',
              }}
            >
              {t('getBloodyLitersSaved')}:{' '}
              <a href={t('bloodyLitersLink')} style={{ color: 'white' }}>
                {bloodyLitters}
              </a>
            </span>
          )}
        </div>
        <>
          {!isLocation && (
            <>
              {!nearestStationsStatus && (
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ m: '4% 0' }}>
                  <Button
                    onClick={() => alert(`${t('locationError')}`)}
                    style={{
                      width: 'auto',
                      height: '47px',
                      padding: '0 14px',
                      width: '75vw',
                      fontSize: '20px',
                      fontWeight: '300',
                      letterSpacing: '3px',
                      textTransform: 'uppercase',
                    }}
                    variant="outline-alt"
                  >
                    {t('btns.getNearestStationsStatus')}
                  </Button>
                </Box>
              )}
            </>
          )}
        </>
      </div>
      <Box
        id="homePageContainer"
        sx={{ m: '0% 7%' }}
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}
      >
        {stationsArr?.length > 0 ? (
          stationsArr?.map(station => {
            return <StationCard station={station} isFetching={loadingNearestStationStatus} key={Math.random()} />;
          })
        ) : (
          <Row style={{ backgroundColor: 'transparent' }} className="mainBtn pr-2 pl-2">
            <p
              style={{
                color: 'inherit',
                textAlign: 'center',
                fontSize: '22px',
              }}
              className="mainBtnText mb-3"
            >
              {t('noNearestStations')}
            </p>
          </Row>
        )}
      </Box>
    </div>
  );
}
