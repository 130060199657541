import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalTextColorLink } from './globalStyles';

export const DistanceKm = ({ distanceMeters, latitude, longitude }) => {
  const [km, setKm] = useState(undefined);

  const { t } = useTranslation();
  const convertedtDistance = () => {
    let km = (distanceMeters * 0.001).toFixed(1);
    setKm(`${km}${t('distanceKm')}`);
  };

  useEffect(() => {
    if (distanceMeters) {
      convertedtDistance();
    }
    // eslint-disable-next-line
  }, [distanceMeters, t]);

  return (
    <ModalTextColorLink
      href={`https://www.google.com/maps/dir//${latitude},${longitude}/@${latitude},${longitude},12z`}
      onClick={e => e.stopPropagation()}
      style={{ textDecoration: 'none' }}
    >
      <div
        style={{
          padding: '7px',
          backgroundColor: '#d2b8d4',
          color: '#FAFAFA',
          fontWeight: '600',
          minWidth: '90px',
          height: '35px',
          fontSize: '14px',
          borderRadius: '11px',
        }}
      >
        <p className="mb-0 align-self-center text-center" style={{ color: 'inherit', whiteSpace: 'nowrap' }}>
          {km ? km : t('map')}
        </p>
      </div>
    </ModalTextColorLink>
  );
};
