// noinspection SpellCheckingInspection
/* eslint-disable */
import { useState } from 'react';
import { Outlet, useSearchParams, useLocation } from 'react-router-dom';
import './layout.css';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, LinksColor, NavBar } from './globalStyles';
import { darkTheme, redesignedDarkTheme } from './darkTheme/Theme';
// import { useLocalStorage } from '../hookServices/useLocalStorage';
import ReactGA from 'react-ga';
import { SVGLogo } from './svgComponents/SVGLogo';
import { useGetBalanceQuery } from '../redux/api';
import { useGetInProgressOrdersCountQuery } from '../redux/api';
import { getClientId } from '../hookServices/clientId';
import { useNavigate } from 'react-router-dom';
import Navigation from '../components/Navigation/Navigation';

export default function Layout() {
  const [open, setOpen] = useState(false);
  const { data: balanceCents } = useGetBalanceQuery(getClientId());
  const { data: inProgressOrdersCount } = useGetInProgressOrdersCountQuery(getClientId());
  const { pathname } = useLocation();
  const navigate = useNavigate();

  let toggleStatus = !open ? 'toggle-icon' : 'open toggle-icon ';

  const closeMenu = () => {
    setOpen(!open);
  };
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  let stationNumber = searchParams.get('station');

  // const { endpoints } = api;
  // const { data: stationStatus } = endpoints.getStationStatus.useQueryState({
  //   stationNumber,
  // });

  // const [togglerStatus] = useLocalStorage('themeTogglerStatus', false);
  // const [, setTheme] = useLocalStorage('themeMode', 'light');
  const hasCharging = true;

  // const darkModeSetter = () => {
  //   setTheme('dark');
  // };
  // const lightModeSetter = () => {
  //   setTheme('light');
  // };

  // useEffect(() => {
  //   if (togglerStatus === false) {
  //     if (stationStatus?.uiNightMode === false) {
  //       lightModeSetter();
  //     } else if (stationStatus?.uiNightMode === true) {
  //       darkModeSetter();
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [stationStatus?.uiNightMode]);

  const isRedesigned = () => ['/', '/start'].includes(pathname);

  return (
    // todo implement theme changes
    // <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
    <ThemeProvider theme={isRedesigned() ? redesignedDarkTheme : darkTheme}>
      <>
        <GlobalStyles />
        <Container
          id="layout"
          fluid
          style={{
            ...(isRedesigned() && {
              fontFamily: 'var(--font-family)',
              fontWeight: '500',
              fontSize: '18px',
              color: '#fff',
              letterSpacing: '0.15em',
            }),
            display: !isRedesigned() && 'flex',
            height: '100vh',
          }}
          className="justify-content-center ml-0 pl-0 mr-0 pr-0"
        >
          <NavBar id="layoutNavBar" className="fixed-top" expand="lg">
            <div className={'header-container'}>
              <LinksColor
                id="layoutLogoLink"
                onClick={() => {
                  ReactGA.event({
                    category: 'logo',
                    action: 'landing from logo click',
                  });
                }}
                to={`/?station=${stationNumber}`}
                className="flex-row align-items-center"
              ></LinksColor>

                <div
                    className={ balanceCents > 0?  'balance-container': 'balance-container-offline'}
                    onClick={() => {
                      ReactGA.event({
                        category: 'header',
                        action: 'history form header',
                      });
                      if (hasCharging) {
                        navigate('/history');
                      }
                    }}
                >
                  <div className={inProgressOrdersCount > 0 ? 'blink_me' : null}>
                    <p className={'textBalance'}>
                      {balanceCents / 100} {t('currency')}
                    </p>
                  </div>
                </div>

              <div className={'header-logo'} onClick={() => navigate(`/?station=${stationNumber}`)}>
                {' '}
                <SVGLogo />
              </div>

              <button id="layoutBurgerBtn" onClick={closeMenu} className={toggleStatus} />
              <div id="layoutHeaderCollapsedMenu">
                {open && <Navigation stationNumber={stationNumber} closeMenu={closeMenu} />}
              </div>
            </div>
          </NavBar>

          <div style={{ marginTop: '133px', marginBottom: '50px' }}>
            <Outlet />
          </div>

          {/*<Footer id="layoutFooter" className="footerBox fixed-bottom">*/}
          {/*  <p id="layoutFooterText" className="footerText">*/}
          {/*    ©2024 {t('footer.part1')}. {t('footer.part2')}{' '}*/}
          {/*    <FooterLink*/}
          {/*        id="layoutFooterLink"*/}
          {/*        className="footerLink"*/}
          {/*        href="https://www.facebook.com/zeBoosterLab/"*/}
          {/*        onClick={() => {*/}
          {/*          ReactGA.event({*/}
          {/*            category: 'zeBosterLab',*/}
          {/*            action: 'facebook page',*/}
          {/*          });*/}
          {/*        }}*/}
          {/*    >*/}
          {/*      ZE Booster Lab*/}
          {/*    </FooterLink>{' '}*/}
          {/*    {t('footer.part3')}*/}
          {/*  </p>*/}
          {/*</Footer>*/}
        </Container>
      </>
    </ThemeProvider>
  );
}
