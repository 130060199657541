import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DropDown from './dropDown/DropDown';
import ErrorPage from '../errorPage/ErrorPage';
import UserInfo from './userInfo/UserInfo';
import './b2bPage.css';
import { LoadingTime } from '../../components/loaders/localLoading/LocalLoading';
import Spinner from '../../components/loaders/globalSpinner/Spinner';
import { defaultMonthObj } from './defaultMonthObj';
import { useRef } from 'react';
import { ENV, HOST, PORT } from '../../environment';
import { useGetUserMeQuery, useGetB2bStationsQuery, useGetB2bReportQuery } from '../../redux/api';
import { changeB2bParams } from './changeB2bParams';
import { QAB2b } from './Q&AB2b';

const date = new Date();
const year = date.getFullYear();

const monthsData = t => {
  return [
    {
      label: `${t('months.december')}(${year - 1})`,
      value: `${year - 1}-12`,
    },
    { label: `${t('months.january')}`, value: `${year}-1` },
    { label: `${t('months.february')}`, value: `${year}-2` },
    { label: `${t('months.march')}`, value: `${year}-3` },
    { label: `${t('months.april')}`, value: `${year}-4` },
    { label: `${t('months.may')}`, value: `${year}-5` },
    { label: `${t('months.june')}`, value: `${year}-6` },
    { label: `${t('months.july')}`, value: `${year}-7` },
    { label: `${t('months.august')}`, value: `${year}-8` },
    { label: `${t('months.september')}`, value: `${year}-9` },
    { label: `${t('months.october')}`, value: `${year}-10` },
    { label: `${t('months.november')}`, value: `${year}-11` },
    { label: `${t('months.december')}`, value: `${year}-12` },
  ];
};

export default function B2bPage({ fingerPrint }) {
  const [checkedMonths, setCheckedMonths] = useState([]);
  const [checkedStations, setCheckedStations] = useState([]);
  const [skipUserMe, setSkipUserMe] = useState(true);
  const [skipB2bReport, setSkipB2bReport] = useState(true);
  const [defaultMonth, setDefaultMonth] = useState(null);

  const b2bParamsFunc = changeB2bParams(checkedMonths, setCheckedMonths, checkedStations, setCheckedStations);

  const { t } = useTranslation();

  const months = useMemo(() => monthsData(t), [t]);

  const userToken = localStorage.getItem('session_id');

  const {
    data: userMe,
    isLoading: isUserMeLoading,
    isError: isErrorUserMe,
    error: errorUserMe,
  } = useGetUserMeQuery({ userToken, fingerPrint }, { skip: skipUserMe });

  const { data, isLoading: isB2bStationsLoading } = useGetB2bStationsQuery({
    userToken,
    fingerPrint,
  });

  const { data: b2bReport } = useGetB2bReportQuery(
    { userToken, months: checkedMonths, station: checkedStations, fingerPrint },
    { skip: skipB2bReport }
  );

  useEffect(() => {
    if (!userMe) {
      setSkipUserMe(false);
    }
    defaultMonthObj(months, setDefaultMonth);
    // eslint-disable-next-line
  }, []);

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      setSkipB2bReport(false);
    }
  }, [checkedMonths, checkedStations]);

  useEffect(() => {
    if (errorUserMe?.status === 401) {
      const local = ENV === 'local' ? ':3000' : '';
      window.open(
        `${HOST}:${PORT}/oauth2/authorization/keycloak?redirect_uri=${HOST}${local}/oauth2/redirect`,
        '_self'
      );
    }
    // eslint-disable-next-line
  }, [errorUserMe]);

  if (isUserMeLoading || errorUserMe?.status === 401 || isB2bStationsLoading) {
    return <Spinner />;
  }

  if (isErrorUserMe && errorUserMe?.status !== 401) {
    return <ErrorPage errorHeader={t('errorDevHeader')} errorBody={t('errorDevBody')} />;
  }
  if (data?.stations?.length === 0) {
    return (
      <>
        <UserInfo userInfo={userMe} token={userToken} fingerPrint={fingerPrint} />
        <ErrorPage errorHeader={t('b2bNoStationsHeader')} errorBody={t('b2bNoStationsBody')} />
        <QAB2b />
      </>
    );
  }

  return (
    <Container id="b2bPage" fluid="lg" style={{ height: '80vh' }} className="d-flex align-items-center">
      <div className="mx-auto">
        <UserInfo userInfo={userMe} token={userToken} fingerPrint={fingerPrint} />
        {defaultMonth && months && data?.stations ? (
          <div id="b2bPageDropDownContainer" style={{ padding: '0 15px' }}>
            <DropDown options={months} defaultValue={defaultMonth} b2bParamsFunc={b2bParamsFunc} />
            <DropDown options={data?.stations} defaultValue={data?.stations} b2bParamsFunc={b2bParamsFunc} />
          </div>
        ) : (
          <>
            <Col id="indicator" className="text-center">
              <LoadingTime />
            </Col>
            <Col id="indicator" className="text-center">
              <LoadingTime />
            </Col>
          </>
        )}
        {b2bReport ? (
          <Row id="b2bPageInfoBox" style={{ padding: '0 15px' }} className="justify-content-center mt-5">
            <Col id="b2bPageCents" className="text-center b2bResult">
              {b2bReport?.cents}
              {t('currency')}
            </Col>
            <Col id="b2bPagePower" className="text-center b2bResult">
              {b2bReport?.kwt}
              {t('powerKwh')}
            </Col>
          </Row>
        ) : (
          <Row id="b2bPageLoadingInfo" style={{ padding: '0 15px' }} className="justify-content-center mt-5">
            <Col id="indicator" className="text-center">
              <LoadingTime />
            </Col>
            <Col id="indicator" className="text-center">
              <LoadingTime />
            </Col>
          </Row>
        )}
      </div>
    </Container>
  );
}
