/* eslint-disable */
import { useEffect, useState } from 'react';
import '../startPage/startPage.css';
import ErrorPage from '../errorPage/ErrorPage';
import { Trans, useTranslation } from 'react-i18next';
import Spinner from '../../components/loaders/globalSpinner/Spinner';
import { useOutletContext } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import { useInterval } from '../../hookServices/useInterval';
import StationNumber from '../../components/stationNumber/StationNumber';
import OnlineStation from './getPower/OnlineStation';
import CongratsFail from './getPower/CongratsFail';
import Congrats from './getPower/Congrats';
import { WebCamera } from '../../components/webCamera/WebCamera';
import { api, useGetStationStatusQuery } from '../../redux/api';
import DelayedStartWaitingPage from '../startPage/DelayedStartWaitingPage';
import { getDate } from '../../utils/utils';

export default function ChargingPage({ fingerPrint }) {
  const [skip, setSkip] = useState(true);
  const [timer, setTimer] = useState(null);
  //todo do we need it?
  const [amountMoney, setAmountMoney] = useState('0.00');

  const { station, stationNumber, openFrom, closedFrom, isTwentyFourHour } = useOutletContext();
  const { endpoints } = api;

  const { isLoading: isDeviceStartLoading } = endpoints.deviceStart.useQueryState({
    stationNumber,
    fingerPrint,
  });

  const {
    data: stationStatus,
    isError: isErrorStationsStatus,
    isLoading: isStationStatusLoading,
  } = useGetStationStatusQuery(
    { stationNumber, fingerPrint },
    {
      skip: skip,
      pollingInterval: 2000,
    }
  );

  let isZero = stationStatus?.lastJob?.chargedWtH === undefined || 0;
  let kWtCharged = Number(stationStatus?.lastJob?.chargedWtH) / 1000;
  let chargeStatus = `${isZero ? ' ' : kWtCharged.toFixed(2)}`;
  //todo extract to global constant
  let carKwtKmRatio = 200;
  let voltage = Number(Math.round(stationStatus?.lastJob?.voltage));
  let kWtPower = Number(stationStatus?.lastJob?.powerWt) / 1000;
  const [stateStation, setStateStation] = useState(null);

  useInterval(() => {
    setStateStation(stationStatus?.lastJob?.state);
  }, 2000);

  const { t } = useTranslation();

  useEffect(() => {
    if (!isDeviceStartLoading) {
      setSkip(false);
    }
    // eslint-disable-next-line
  }, [isDeviceStartLoading]);

  //todo do we need this?
  const spendingMoney = () => {
    let kWtCharged = Number(stationStatus?.lastJob?.chargedWtH) * 1000;
    const kWtPeriod = Number(stationStatus?.lastJob?.periodWt) * 1000;
    const cost = station?.costPerKwt * kWtPeriod;

    let amountHrn = Number(((cost * kWtCharged) / kWtPeriod).toFixed(2)) || '0.00';
    setAmountMoney(amountHrn.toString());
  };

  useEffect(() => {
    if (stationStatus?.lastJob?.state === 'DONE' || isErrorStationsStatus) {
      setSkip(true);
    }
  }, [stationStatus?.lastJob?.state, isErrorStationsStatus]);

  useEffect(() => {
    if (stationStatus?.lastJob?.leftS) {
      setTimer(getDate(stationStatus?.lastJob?.leftS));
    }
    // eslint-disable-next-line
  }, [stationStatus?.lastJob?.state]);

  useInterval(() => {
    if (stationStatus?.lastJob?.state === 'IN_PROGRESS') {
      spendingMoney();
      setTimer(getDate(stationStatus?.lastJob?.leftS));
    }
  }, 15000);

  console.log(stationStatus)

  if (isErrorStationsStatus) {
    return <ErrorPage errorHeader={t('errorDevHeader')} startAgain = {true} errorBody={t('errorDevBody')}  />;
  }

  if (stationStatus?.lastJob?.state === 'FAILED') {
    return (
      <ErrorPage
        errorHeader={t('errorStartFailedHeader')}
        errorBody={t('errorStartFailedBody')}
        stationNumber={stationNumber}
        startAgain={true}
      />
    );
  }

  if (!stationStatus || (stationStatus?.lastJob?.state !== 'IN_PROGRESS' &&  stationStatus?.lastJob?.state !== 'DONE')) {
    return <Spinner />;
  }

  return (
    <Container id="chargingPage" style={{ overflowX: 'hidden', paddingLeft: '0', paddingRight: '0' }}>
      <div id="getPowerContainer" className="mainBlock col-md-12 mt-2">
        <StationNumber
          justifyContent="justify-content-center"
          station={stationNumber}
          costPerKwt={station?.costPerKwt}
          distanceMeters={station?.distanceMeters}
          latitude={station?.latitude}
          longitude={station?.longitude}
          plugshareLink={station?.plugshareLink}
          plugType={station?.plugType}
        />
        {!isTwentyFourHour ? (
          <Row id="startPageTitleBox" className="justify-content-center mt-0 mb-4 pr-1 pl-1">
            <p className="delayedStartTime">
              <Trans i18nKey={'delayedInfo'}>
                {/*todo add to i18n*/}
                Порт працює з {{ openFrom }} до
                {{ closedFrom }}
              </Trans>
            </p>
          </Row>
        ) : null}
        {stationStatus?.lastJob?.state === 'WAITING' && (
          <DelayedStartWaitingPage leftTimeToStartS={stationStatus?.lastJob?.leftTimeToStartS} />
        )}
        {stationStatus?.lastJob?.state === 'IN_PROGRESS' && (
          <OnlineStation
            station={station}
            stationStatus={stationStatus}
            // todo pass only station and stationStatus
            periodKwh={stationStatus.lastJob?.periodKwt}
            carKwtKmRatio={carKwtKmRatio}
            kWhCharged={kWtCharged}
            costPerKwt={station?.costPerKwt}
            voltage={voltage}
            kWtPower={kWtPower}
            chargeStatus={chargeStatus}
            onlineStatus={stationStatus?.lastJob?.deviceOnline}
            timer={timer}
            isZero={isZero}
            lastJob={stationStatus?.lastJob}
            amountMoney={amountMoney}
            type={stationStatus?.lastJob?.type}
            invoiceId={stationStatus?.lastJob?.invoiceId}
          />
        )}
        {stationStatus?.lastJob?.state === 'DONE' && !stationStatus?.lastJob?.stopReason?.includes("WARN. Power") &&
            (
          <Congrats
            kWtCharged={kWtCharged}
            carKwtKmRatio={carKwtKmRatio}
            chargeStatus={chargeStatus}
            stationNumber={stationNumber}
            amountMoney={amountMoney}
            station={station}
          />
        )}
        {stationStatus?.lastJob?.state === 'DONE' && stationStatus?.lastJob?.stopReason?.includes("WARN. Power") &&
            (
                <CongratsFail
                    kWtCharged={kWtCharged}
                    carKwtKmRatio={carKwtKmRatio}
                    chargeStatus={chargeStatus}
                    stationNumber={stationNumber}
                    amountMoney={amountMoney}
                />
            )}

        <WebCamera stationNumber={stationNumber} webcamUrl={station?.webcamUrl} />
      </div>
    </Container>
  );
}
