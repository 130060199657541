import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Filler,
} from 'chart.js';
import 'chartjs-adapter-luxon';
import ChartStreaming from 'chartjs-plugin-streaming';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Legend, ChartStreaming, Filler);

export function Chart({ power, voltage }) {
  const [powerChart] = useState([]);
  const [voltageChart] = useState([]);
  const { t } = useTranslation();

  const labels = [];
  for (let time = 0; time <= 720; time++) {
    labels.push(time);
  }

  const onRefresh = () => {
    const now = Date.now();

    data.datasets[1].data.push({
      x: now,
      y: voltage,
    });
    data.datasets[0].data.push({
      x: now,
      y: power.currentKw,
    });
  };

  const data = {
    datasets: [
      {
        label: t('power'),
        backgroundColor: 'rgba(143,0,255,0.09)',
        borderColor: 'rgba(143,0,255,0.41)',
        fill: true,
        lineTension: 0,
        borderDash: [8, 4],
        cubicInterpolationMode: 'monotone',
        yAxisID: 'power',
        data: powerChart,
      },
      {
        label: t('voltage'),
        backgroundColor: 'rgba(169,149,207,0.16)',
        borderColor: 'rgba(169,149,207,0.22)',
        fill: true,
        lineTension: 0,
        borderDash: [8, 4],
        cubicInterpolationMode: 'monotone',
        yAxisID: 'voltage',
        data: voltageChart,
      },
    ],
  };

  const options = {
    responsive: true,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        onClick: () => null,
      },
    },
    scales: {
      power: {
        type: 'linear',
        display: true,
        position: 'left',
        min: 0,
        max: power.maxKw,
      },
      voltage: {
        type: 'linear',
        display: false,
        position: 'left',
        min: 200,
        max: 220,
        grid: {
          drawOnChartArea: false,
        },
      },
      x: {
        type: 'realtime',
        distribution: 'linear',
        realtime: {
          duration: 20000,
          refresh: 1000,
          delay: 1000,
          onRefresh: onRefresh,
        },
        ticks: {
          callback: function (value) {
            return moment(value, 'HH:mm:ss').format('mm:ss');
          },
        },
      },
    },
  };
  return (
    <div
      id="chartBox"
      //  className="chartWidth"
      style={{ width: '100%', padding: '0 20px 0 20px' }}
    >
      <Line options={options} data={data} />
    </div>
  );
}
