import cn from 'classnames';
import styles from './button.module.css';

export const Button = ({ children, className, variant, onClick, link, ...props }) => {
  return (
    <button
      type="button"
      className={cn(
        styles.button,
        variant === 'outline' && styles.outline,
        variant === 'outline-alt' && styles.outlineAlt,
        className
      )}
      onClick={onClick}
      {...props}
    >
      {link ? (
        <a href={link} style={{ display: 'flex', flexWrap: 'nowrap' }}>
          {children}
        </a>
      ) : (
        children
      )}
    </button>
  );
};
