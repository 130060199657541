/* eslint-disable */
import './historyPage.css';
import {useEffect, useState} from 'react';
import {useGetHistoryDataQuery} from '../../redux/api';
import {Container, Row} from 'react-bootstrap';
import {Typography} from '@mui/material';
import {
  AccordionSummaryCustom,
  AccordionDetailsCustom,
  ExpandMoreIconCustom,
  ColCustom,
  HistoryLink,
  AccordionCustom,
} from '../../components/globalStyles';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {plugTypeMaping} from '../../utils/utils';
import {getClientId} from '../../hookServices/clientId';
import Spinner from '../../components/loaders/globalSpinner/Spinner';
import {Button} from "../../shared/ui";
import {LoadingSm} from "../../components/loaders/localLoading/LocalLoading";

moment.locale('ua', {
  monthsShort: 'Cіч_Лют_Бер_Кві_Тра_Чер_Лип_Сер_Вер_Жов_Лис_Гру'.split('_'),
});

export default function HistoryPage() {
  const getLanguage = localStorage.getItem('i18nextLng');
  // eslint-disable-next-line no-unused-vars
  const [currentLanguage, setCurrentLanguage] = useState(getLanguage);
  const {data} = useGetHistoryDataQuery(getClientId());
  const historyData = data;
  const formatTime = 'DD MMM HH:mm';

  const {t} = useTranslation();

  //todo do not copypaste
  // const checkInvoiceId = localStorage.getItem('invoiceId') === invoiceId;
  const urlProtocol = window.location.protocol;
  const urlHost = window.location.host;
  const [hideSpinner, setHideSpinner] = useState(true);

  function stopProcessCharging(portNumber) {
    setHideSpinner(false)
    fetch(`${urlProtocol}//${urlHost}:8080/api/device/v2/stop`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({clientId: getClientId()}),
    })
    .then(response => {
      if (!response.ok && response.status !== 404) {
        throw new Error('http error!');
      }
      window.location.href = `/charging?station=${portNumber}`
    })
    .catch(e => {
      throw new Error(e);
    });
  }

  useEffect(() => {
    setCurrentLanguage(getLanguage);
    moment.locale(getLanguage);
  }, [getLanguage]);

  if (!historyData) {
    return <Spinner/>;
  }

  return (
      <Container className={'p-0 m-0 pt-3 pb-5'} style={{marginTop: '50px'}}>
        {historyData.map(
            (
                {
                  plugNumber: portNumber,
                  startedOn,
                  priceInCents,
                  durationSeconds,
                  energyWth,
                  finishedOn,
                  refundedCents,
                  balanceCents,
                  state,
                  address,
                  plugType,
                },
                index
            ) => {
              function defineTime() {
                const remainingHours = Math.floor(durationSeconds / 3600);
                const remainingMinutes = Math.round(
                    (durationSeconds % 3600) / 60);
                return (
                    <>
                      {remainingHours} {t(
                        'historyPage.timeHours')} {remainingMinutes} {t(
                        'historyPage.timeMinutes')}
                    </>
                );
              }

              return (
                  <AccordionCustom key={index} className={''}>
                    <AccordionSummaryCustom
                        expandIcon={<ExpandMoreIconCustom/>}
                        aria-controls={'panel1a-content'}
                        id={'panel1a-header'}
                    >
                      <Typography
                          className={'w-100 d-flex justify-content-between'}>
                        <span>{moment(startedOn).format(formatTime)} </span>
                        {state === 'TOP_UP_BALANCE' ? <span
                            style={{color: 'green'}}> + {refundedCents
                            / 100} {t(
                            'currency')} </span> : null}
                        <div className={state === "IN_PROGRESS" ? "blink_me"
                            : null}>
                  <span
                      style={{marginLeft: '5px', animation: 'blink-animation'}}>
                    {(energyWth / 1000).toFixed(1)} {t('wt')}
                  </span>
                        </div>
                      </Typography>{' '}
                    </AccordionSummaryCustom>
                    <AccordionDetailsCustom>
                      <Row className={'cols-2 '}>
                        <HistoryLink className={'historyLink'}
                                     to={`/start?station=${portNumber}`}>
                          <ColCustom>
                            {t('historyPage.station')} {portNumber} <br/>
                            {plugType && `(${plugTypeMaping[plugType]})`}
                          </ColCustom>
                        </HistoryLink>
                        <ColCustom>
                          {t('historyPage.price')}:
                          <br/>
                          {priceInCents / 100} {t('currency')}
                        </ColCustom>
                        <ColCustom>
                          {balanceCents === 0 ? t('historyPage.refund') : t(
                              'historyPage.balance')}:
                          <br/>
                          {balanceCents === 0 ? refundedCents / 100
                              : balanceCents / 100} {t('currency')}
                        </ColCustom>
                      </Row>
                      <Row>
                        <ColCustom className={'justify-content-around'}>
                          <div className={state === "IN_PROGRESS" ? "blink_me"
                              : null}>
                            {t('historyPage.amountS')}:{' '}
                            {/*https://esound.youtrack.cloud/youtrack/agiles/87-6/current?issue=220-800*/}
                            {defineTime()}{' '}
                          </div>
                        </ColCustom>
                      </Row>
                      {address && (
                          <Row>
                            <ColCustom
                                className={'justify-content-around'}>{`(${address})`}</ColCustom>
                          </Row>
                      )}
                      {state === "IN_PROGRESS" && (
                          <Row>
                            <ColCustom className={'justify-content-around'}>
                              <Row className={'m-2'}>
                                {hideSpinner && (
                                    <Button onClick={() => stopProcessCharging(
                                        portNumber)}>
                                  <span className="mainBtnText">{t(
                                      'stopButton')}</span>
                                    </Button>
                                )}

                                {!hideSpinner && (
                                    <LoadingSm/>
                                )}
                              </Row>
                            </ColCustom>
                          </Row>
                      )}
                    </AccordionDetailsCustom>
                  </AccordionCustom>
              );
            }
        )}
      </Container>
  );
}
