/* eslint-disable */
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FullInfo from '../fullInfo/FullInfo';
import { PowerMetricsColor, VoltageBtn } from '../../../components/globalStyles';
import ChargingProgress from '../../../components/chargingProgress/ChargingProgress';
import Timer from '../../../components/timer/Timer';
import { LoadingSm } from '../../../components/loaders/localLoading/LocalLoading';
import ModalContacts from '../../contactsPage/modal/ModalContacts';
import { Button } from '@ui';


import { getClientId } from '../../../hookServices/clientId';
import {Icon} from "../../../shared/ui";

export default function OnlineStation({
  station,
  stationStatus,
  periodKwh,
  carKwtKmRatio,
  kWhCharged,
  costPerKwt,
  kWtPower,
  chargeStatus,
  onlineStatus,
  timer,
  isZero,
  lastJob,
  invoiceId,
}) {
  const [over, setOver] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const checkInvoiceId = localStorage.getItem('invoiceId') === invoiceId;
  const urlProtocol = window.location.protocol;
  const urlHost = window.location.host;
  const [hideSpinner, setHideSpinner] = useState(true);

  function callToSupport() {
    if (!onlineStatus) {
      setIsOpen(true);
    }
  }

  function stopProcessCharging() {
    setHideSpinner(false)
    fetch(`${urlProtocol}//${urlHost}:8080/api/device/v2/stop`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({clientId: getClientId()}),
    })
    .then(response => {
      if (!response.ok && response.status !== 404) {
        throw new Error('http error!');
      }

      return response.json();
    })
    .then(({data}) => console.log(data))
    .catch(e => {
      throw new Error(e);
    });
  }

  return (
    <div id="onlineStationBox">
      <Row id="onlineStationKmMetricsBox" className="justify-content-center infoContainer">
        <Col id="onlineStationKmChargedBox" xs lg={6} className="text-center">
          <PowerMetricsColor id="onlineStationKmChargedTitle" className="mb-0 textTitle">
            {t('charging')}
          </PowerMetricsColor>
          {/* charged  km */}
          <p id="onlineStationKmChargedInfo" className="textInfo text">
            {isZero ? 0 : ((kWhCharged * 1000) / carKwtKmRatio).toFixed(2)}
            {t('km')}
            {/* {type !== "SECONDS" && (
              <span
                id="onlineStationKmChargedInfo"
                style={{ fontSize: "14px" }}
              >
                {amountMoney}
                {t("currency")}
              </span>
            )} */}
          </p>
        </Col>
        <Col id="onlineStationKmPowerBox" xs lg={6} className="text-center">
          <PowerMetricsColor id="onlineStationKmPowerTitle" className="mb-0 textTitle">
            {t('spent')}
          </PowerMetricsColor>
          <p id="onlineStationKmPowerInfo" className="textInfo text">
            {(kWhCharged * costPerKwt).toFixed(2)} {t('currency')}
          </p>
        </Col>
      </Row>
      <ChargingProgress
        status={lastJob}
        online={onlineStatus}
        widthContainer={'120px'}
        marginContainer={'0 auto'}
        widthDot={'20px'}
        heightDot={`20px`}
      />
      {timer !== null ? (
        <Timer
          hours={Number(timer[0])}
          minutes={Number(timer[1])}
          seconds={Number(timer[2])}
          fontSize={'calc(1rem + 1vw)'}
          margin={'0'}
          over={over}
          setOver={setOver}
          onlineStatus={onlineStatus}
        />
      ) : (
        <LoadingSm />
      )}
      <Row id="onlineStationVoltageBox" className="justify-content-center mt-2">
        <VoltageBtn id="onlineStationVoltageBtn" xs="auto" className="text-center activeBtnVoltage">
          <PowerMetricsColor id="onlineStationVoltageTitle" className="mb-0 textTitle voltTitle">
            {t('power')}
          </PowerMetricsColor>
          {/*todo rename css*/}
          <p id="onlineStationVoltageInfo" className="voltTitle textInfo text">
            {onlineStatus === false ? (
              <>{t('onlineStatus')}</>
            ) : (
              <>
                {kWtPower.toFixed(2)} {t('powerWt')}
              </>
            )}
          </p>
        </VoltageBtn>
      </Row>

      <FullInfo
        station={station}
        stationStatus={stationStatus}
        periodKwh={periodKwh}
        lastJob={lastJob}
        kWtPower={kWtPower}
        chargeStatus={chargeStatus}
        onlineStatus={onlineStatus}
      />

      {!onlineStatus && (
        <Button onClick={callToSupport} className="mainBtn buttonForOfflineStation">
          <span className="mainBtnText">{t('callUs')}</span>
        </Button>
      )}

      {hideSpinner && checkInvoiceId && onlineStatus && (
        <Row className={'m-2'}>
          <Button onClick={stopProcessCharging} className="mainBtn buttonForOfflineStation w-100">
            <span className="mainBtnText">{t('stopButton')}</span>
          </Button>
        </Row>
      )}

      {!hideSpinner && checkInvoiceId && onlineStatus && (
           <LoadingSm/>
      )}

      <ModalContacts modalOpen={isOpen} setModalOpen={setIsOpen} />
      {station?.plugshareLink && onlineStatus && (
          <Row className={'m-2'}>
            <Button  link={station?.plugshareLink} className="mainBtn buttonForOfflineStation w-100">
              <span className="mainBtnText"> {t('leaveFeedback')}</span>
            </Button>
          </Row>
      )}
    </div>
  );
}
