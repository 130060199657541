import React from 'react';
import { useTranslation } from 'react-i18next';
import Emoji from '../../../components/emoji/Emoji';
import Modal from '../../../components/modal/Modal';
import '../contactsPage.css';
import ReactGA from 'react-ga';
import { ModalTextColorLink } from '../../../components/globalStyles';
import { MAX_PHONE_NUM, HLIB_PHONE_NUM } from '../../../utils/contacts';
import { splitToCorrectNumber } from '../../../utils/utils';

export default function ModalContacts({ modalOpen, setModalOpen }) {
  const { t } = useTranslation();

  return (
    <Modal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div id="modalContactsBox" className="telephoneContainer">
        <p id="modalContactsTitle" className="tapForCall">
          {t('tapCall')}:
        </p>
        <div id="modalContactsMaxPhoneNumberBox" className="numberCont">
          <Emoji symbol="📲" label="phone" />
          <ModalTextColorLink
            id="modalContactsNumberLinkMax"
            href={MAX_PHONE_NUM ? `tel:${MAX_PHONE_NUM[0]}` : '#'}
            className="telephone"
            onClick={() => {
              ReactGA.event({
                category: 'call1',
                action: 'call to 1',
              });
            }}
          >
            {MAX_PHONE_NUM ? splitToCorrectNumber(MAX_PHONE_NUM[1]) : t('error')}
          </ModalTextColorLink>
        </div>
        <div id="modalContactsDimaPhoneNumberBox" className="numberCont">
          <Emoji symbol="📲" label="phone" />
          <ModalTextColorLink
            id="modalContactsNumberLinkDima"
            href={HLIB_PHONE_NUM ? `tel:${HLIB_PHONE_NUM[0]}` : '#'}
            className="telephone"
            onClick={() => {
              ReactGA.event({
                category: 'call1',
                action: 'call to 2',
              });
            }}
          >
            {HLIB_PHONE_NUM ? splitToCorrectNumber(HLIB_PHONE_NUM[0]) : t('error')}
          </ModalTextColorLink>
        </div>
      </div>
    </Modal>
  );
}
