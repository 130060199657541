import './BtnCommon.css';
import { useTranslation } from 'react-i18next';

const BtnCommon = ({ funct, textButton, argForFunct }) => {
  const { t } = useTranslation();

  return (
    <div className={'btnCommon-container'}>
      <div className={'mainBtn btnCommon'} onClick={() => funct(argForFunct)}>
        <p className={'mainBtnText'}>{t(`${textButton}`)}</p>
      </div>
    </div>
  );
};

export default BtnCommon;
