import { v4 as uuidv4 } from 'uuid';

export const saveClientId = () => {
  const userId = localStorage.getItem('clientId');
  if (userId) {
    return;
  } else {
    const clientId = uuidv4();
    localStorage.setItem('clientId', clientId);
  }
};

export const getClientId = () => {
  return localStorage.getItem('clientId');
};
